import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import { IoBusiness } from "react-icons/io5";
import { MdBusiness } from "react-icons/md";
import { AiFillHome } from "react-icons/ai";
import clsx from "clsx";
import { FaCarAlt, FaCar } from "react-icons/fa";
import { HiHomeModern } from "react-icons/hi2";
import useWindowSize from "~/hooks/useWindowSize";

function HeaderEl({ text, Icon, href, isActive = false, isExternal = false }) {
  if (isExternal) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        <div
          className={clsx(
            "flex flex-col gap-0.5 items-center hover:text-brand-primary hover:cursor-pointer",
            {
              "text-brand-primary": isActive,
            }
          )}
        >
          {Icon}
          <span className="text-xxs lg:text-xs">{text}</span>
        </div>
      </a>
    );
  }
  return (
    <Link href={href}>
      <a>
        <div
          className={clsx(
            "flex flex-col gap-0.5 items-center hover:text-brand-primary hover:cursor-pointer",
            {
              "text-brand-primary": isActive,
            }
          )}
        >
          {Icon}
          <span className="text-xxs lg:text-xs">{text}</span>
        </div>
      </a>
    </Link>
  );
}

export default function SubHeader() {
  const router = useRouter();
  const { width } = useWindowSize();

  const currentRoute = router.pathname;
  const completeRoutePath = router.asPath;

  return (
    <div className="md:h-28 px-2 md:px-28 lg:px-32 2xl:px-72 bg-brand-dark flex flex-col border-b-4 border-brand-primary gap-2 md:flex-row w-full items-center justify-between py-2 ">
      <Link href="/">
        <a>
          <Image
            src="/logo.png"
            width="456px"
            height="80px"
            alt="seminuevossonora logo"
          />
        </a>
      </Link>
      <ul className="flex text-gray-300 gap-6 lg:gap-8">
        <li>
          <HeaderEl
            href="/"
            isActive={currentRoute === "/"}
            text="Inicio"
            Icon={<AiFillHome size={width > 380 ? 24 : 20} />}
          />
        </li>
        <li>
          <HeaderEl
            href="/autos"
            isActive={currentRoute === "/autos"}
            Icon={<FaCarAlt size={width > 380 ? 24 : 20} />}
            text="Autos"
          />
        </li>
        <li>
          <HeaderEl
            isActive={currentRoute === "/autos/recomendados"}
            href="/autos/recomendados"
            Icon={<FaCar size={width > 380 ? 24 : 20} />}
            text="Recomendados"
          />
        </li>

        <li>
          <HeaderEl
            isActive={completeRoutePath === "/negocios?category=Lote"}
            href="/negocios?category=Lote"
            Icon={<MdBusiness size={width > 380 ? 24 : 20} />}
            text="Lotes"
          />
        </li>

        <li>
          <HeaderEl
            isActive={
              completeRoutePath === "/negocios?category=Agencia%20Seminuevos"
            }
            href="/negocios?category=Agencia Seminuevos"
            Icon={<IoBusiness size={width > 380 ? 24 : 20} />}
            text="Agencias"
          />
        </li>
        <li>
          <HeaderEl
            text="Inmuebles"
            href="https://inmueblessonora.com/"
            Icon={<HiHomeModern size={width > 380 ? 24 : 20} />}
            isExternal
          />
        </li>
      </ul>
    </div>
  );
}
